import { useKeycloak } from '@react-keycloak/web';
import React, { FC, useEffect, useState } from 'react';
import { useAppDispatch } from '../../state/hooks';
import { useSelector } from 'react-redux';
import { Button, Card, CardActions, CardContent, CardHeader, Dialog, Grid, Modal, TextField } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { supplierSelector, getSupplier, deleteSupplier } from '../../state';

export const ShowSupplier: FC = () => {
    const dispatch = useAppDispatch();
    const { keycloak, initialized } = useKeycloak();
    const [walletUrl, setWalletUrl] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(true);

    const supplier = useSelector(supplierSelector);
    let navigate = useNavigate();

    useEffect(() => {
        dispatch(getSupplier({jwtToken: keycloak.token!})) .then((data) => {
                if (data.type.includes('fulfilled')) {
                    if (!data.payload) {
                        navigate('/register');
                    } else {
                        setLoading(false);
                    }
                }
            }
        );
    }, [keycloak.token]);

    function handleDelete() {
        if (supplier.singleItem) {
            dispatch(deleteSupplier({jwtToken: keycloak.token!, supplierId: supplier.singleItem!.id})).then((response) => {
                if (response.type.includes('fulfilled')) {
                    setTimeout(() => { // Use timeout the give time to update the redux store.
                        navigate('/');
                    }, 250);
                }
            });
        }
    }

    return (
        !loading && (supplier.singleItem !== undefined) ?
        <div>
            <Grid container spacing={2} flexDirection={'row'}>
                <Grid item xs={12}>
                    <Card sx={{background: '#f9da70', color: '#000000', borderRadius: 8, padding: 3}} variant="outlined" >
                        <CardHeader sx={{fontFamily: 'Batonturboweb Bold, sans-serif', fontSize: '2rem', fontWeight: 700}} title='Supplier profile'/>
                        <CardContent>
                            <Grid container spacing={2}>
                                <Grid item xs={4}>
                                    Naam:
                                </Grid>
                                <Grid item xs={8}>
                                    {supplier.singleItem?.naam}
                                </Grid>
                                <Grid item xs={4}>
                                    KvK nummer:
                                </Grid>
                                <Grid item xs={8}>
                                    {supplier.singleItem?.kvkNummer}
                                </Grid>
                                <Grid item xs={4}>
                                    BTW nummer:
                                </Grid>
                                <Grid item xs={8}>
                                    {supplier.singleItem?.btwNummer}
                                </Grid>
                            </Grid>
                        </CardContent>
                        <CardActions>
                            <Button sx={{ background: '#3e2f0d', color: '#ffffff'}} onClick={handleDelete}>Delete</Button>
                        </CardActions>
                    </Card>
                </Grid>
            </Grid>
        </div> : <></>
    );
};
