import { createAsyncThunk } from '@reduxjs/toolkit';
import { setLoading } from '../global';

import { bearerAuth } from '../auth';
import axios from 'axios';


export const getSupplier = createAsyncThunk(
        'supplier/getSupplier', ({jwtToken}: {jwtToken: string | undefined}, thunkAPI) => {
            thunkAPI.dispatch(setLoading(true));
            const config = {
                headers: {'Authorization': bearerAuth(jwtToken)}
            };
            return axios.get('/manage/supplier', config)
                .then(response => {
                    return response.data
                })
                .finally(() => {
                    thunkAPI.dispatch(setLoading(false));
                });
        },
);

export const deleteSupplier = createAsyncThunk(
    'credential/deleteSupplier', ({jwtToken, supplierId}: { jwtToken: string | undefined, supplierId: number }, thunkAPI) => {
        thunkAPI.dispatch(setLoading(true));
        const config = {
            headers: {'Authorization': bearerAuth(jwtToken)}
        };
        ;
        return axios.delete(`/manage/supplier/${supplierId}`, config)
            .then(response => {
                return response.data
            })
            .finally(() => {
                thunkAPI.dispatch(setLoading(false));
            });
    },
);

export const registerSupplier = createAsyncThunk(
    'supplier/registerSupplier', ({jwtToken, sessionId}: {jwtToken: string| undefined, sessionId: string}, thunkAPI) => {
        thunkAPI.dispatch(setLoading(true));
        const config = {
            headers: {'Authorization': bearerAuth(jwtToken)}
        };
        return axios.get('/manage/supplier/register/' + sessionId, config)
            .then(response => {
                return response.data
            })
            .finally(() => {
                thunkAPI.dispatch(setLoading(false));
            });
    },
);
