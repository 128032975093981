import { Box, Link, Typography } from '@mui/material';
import React from 'react';
import styled from 'styled-components';
import { useKeycloak } from '@react-keycloak/web';
import { Logo } from '../atoms';

interface Props {
}


export const Header: React.FC<Props> = (props) => {
    let {keycloak} = useKeycloak();

    function logoff() {
        keycloak.logout();
    }

    function logon() {
        keycloak.login();
    }

    return (
        <Box>
            <Box sx={{background: '#3e2f0d', height: '40px', display: 'flex', alignItems: 'center', textAlign: 'center', justifyContent: 'flex-end', padding: '.5rem 0', paddingLeft: '80px', paddingRight: '80px'}}>
                <Link sx={{display: 'flex', alignItems: 'center', color: '#ffffff'}} underline="none">
                    <svg aria-hidden="true" aria-label="" focusable="false" height="16px" version="1.1" viewBox="0 0 64 64" width="16px" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M44.962,17.355 C44.6291591,10.438499 38.924505,5.00176678 32,5.00176678 C25.075495,5.00176678 19.3708409,10.438499 19.038,17.355 C17.814,39.536 46.187,39.56 44.962,17.355 Z M23.945,17.632 C24.508,7.432 39.493,7.448 40.055,17.632 C40.9,32.87 23.1,32.886 23.945,17.632 Z M32,37 C17.809,37 9.447,42.27 8.45,51.878 L8,57.006 L12.928,57.006 C13.387,52.006 12.228,41.99 31.999,41.99 C51.448,41.99 50.574,51.006 51.074,57.006 L56,57.006 C55.521,52.006 56.584,37 32,37 Z"></path>
                    </svg>
                    {keycloak.authenticated && (
                        <Typography sx={{paddingLeft: '8px'}} onClick={logoff}>Uitloggen</Typography>
                    )}
                    {!keycloak.authenticated && (
                        <Typography sx={{paddingLeft: '8px'}} onClick={logon}>Inloggen</Typography>
                    )}
                </Link>
            </Box>
            <Box sx={{display: 'flex', alignItems: 'center', textAlign: 'center', justifyContent: 'flex-start', background: '#ffffff', padding: '.5rem 0', paddingLeft: '80px', paddingRight: '80px', height: '66px'}}>
                <Logo/>
            </Box>
        </Box>
    );
};

const TopRow = styled.div`
    background: linear-gradient(90deg, #ff9300, #aa418c);
    height: 8px;
`;
const NavRow = styled.nav`
    display: flex;
    align-items: center;
    background: #efefef;
    justify-content: flex-end;
    padding-right: 40px;
`;
const MenuItem = styled.li`
    //list-style: none;
`;
