import { createSlice } from '@reduxjs/toolkit';
import { defaultGenericState, GenericState } from '../model';
import { addGenericStateListBuilders, addGenericStateSingleBuilders } from '../slice';
import { getLoginCredential, verifyCredential, verifyLoginCredential } from './CredentialApi';


export interface Credential {
    oidcUrl: string;
    sessionId: string;
}


export interface CredentialState extends GenericState<Credential> {
}

export const credentialSlice = createSlice({
    name: 'credential',
    initialState: defaultGenericState,
    reducers: {},
    extraReducers: builder => {
        addGenericStateSingleBuilders(builder, verifyCredential);
        addGenericStateSingleBuilders(builder, verifyLoginCredential);
        addGenericStateListBuilders(builder, getLoginCredential);

    },
});
