import { useKeycloak } from '@react-keycloak/web';
import React, { FC, useEffect, useState } from 'react';
import { useAppDispatch } from '../../state/hooks';
import { useSelector } from 'react-redux';
import { Button, Card, CardActions, CardContent, CardHeader, Grid, Modal, TextField } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { BackgroundCardShape } from '../atoms';
import { credentialSelector, verifyCredential } from '../../state/slices/credential';

export const RegisterSupplier: FC = () => {
    const dispatch = useAppDispatch();
    const { keycloak, initialized } = useKeycloak();
    const [walletUrl, setWalletUrl] = useState<string>('');
    const credentialState = useSelector(credentialSelector);
    let navigate = useNavigate();

    function startPresentationExchange() {
        dispatch(verifyCredential({jwtToken: keycloak.token!, walletUrl: walletUrl}))
            .then((data) => {
                    if (data.type.includes('fulfilled')) {
                        window.location.replace(data.payload.oidcUrl);
                    }
                }
            );
    }

    return (
        <div>
            <Grid container spacing={2} flexDirection={'row'}>
                <Grid item xs={12}>
                    <Card sx={{background: '#f9da70', color: '#000000', borderRadius: 8, padding: 3}} variant="outlined" >
                        <CardHeader sx={{fontFamily: 'Batonturboweb Bold, sans-serif', fontSize: '2rem', fontWeight: 700}} title='Register as a new supplier'/>
                        <CardContent>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    Start the registration process by presenting your KVK Registration and Tax ID from your wallet.
                                </Grid>
                                <Grid container spacing={2} sx={{padding: 2, alignItems: 'center'}}>
                                    <Grid item xs={3}>
                                        Enter your wallet address
                                    </Grid>
                                    <Grid item xs={9}>
                                        <TextField sx={{ backgroundColor: '#ffffff', outlineColor: '#58e4d0', borderRadius: 2}} variant='outlined' label="Specify the Wallet URL" fullWidth margin="dense" onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            setWalletUrl(event.target.value);
                                        }}></TextField>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </CardContent>
                        <CardActions>
                            <Button sx={{ background: '#3e2f0d', color: '#ffffff'}} onClick={() => startPresentationExchange()}>Share documents</Button>
                        </CardActions>
                    </Card>
                </Grid>
            </Grid>
        </div>
    );
};
