import { createSlice } from '@reduxjs/toolkit';
import { defaultGenericState, GenericState } from '../model';
import { addGenericStateSingleBuilders } from '../slice';
import { deleteSupplier, getSupplier } from './SupplierApi';


export interface Supplier {
    id: number;
    kvkNummer: string;
    naam: string;
    btwNummer: string;
}

export interface SupplierState extends GenericState<Supplier> {
}

export const supplierSlice = createSlice({
    name: 'supplier',
    initialState: defaultGenericState,
    reducers: {},
    extraReducers: builder => {
        addGenericStateSingleBuilders(builder, getSupplier);
        addGenericStateSingleBuilders(builder, deleteSupplier);
    },
});
